
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { onMounted } from "vue";
import router from "@/router";
import store from "@/store";
export default {
  name: "faq",

  setup() {
    onMounted(() => {
      let user = store.getters.currentUser;
      if (Object.keys(user).length == 0) {
        router.push({ path: "/sign-in" });
      }
      setCurrentPageBreadcrumbs("FAQ", ["Support Center"]);
    });
  },
};
